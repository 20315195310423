import classes from "../css/abs.css";
import React, { useState, useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../images/logoOfSchool.png";
import user from "../images/user.jpg";
import UsewindowDimension from "./hooks/UsewindowDimension.js";
import "../css/Sidebarone.css";
import SidebarContext from "./sidebarcontext/SidebarContext";
import AuthContext from "./auth-context";
import AlbumContext from "./contextfolder/AlbumContext/AlbumContext";
import StudentContext from "./contextfolder/StudentContext/StudentContext";
import SidebarItem from "./SidebarItem";
import GalleryContext from "./contextfolder/GalleryContext/GalleryContext";
import MarksInputContext from "./contextfolder/MarksInputContext/MarksInputContext";
// import items from "../component/sidebar.json"

export default function Sidebarone(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));
  const UserName = userinfo.UserName;
  const FullName = userinfo.FullName;
  // const CustomerImage = userinfo.CustomerImage

  const { setPopupA } = useContext(AlbumContext);
  const { setPopups } = useContext(GalleryContext);
  const { setSelectClass, setSelectedOptions, setStudentList } =
    useContext(StudentContext);

  const context = useContext(SidebarContext);
  const { menutoggle, setMenutoggle, setAlert, logoutdata } = context;

  const {
    setSelectSection,
    setSelectTeacher,
    setSelectSubject,
    setSelectTerm,
  } = useContext(MarksInputContext);

  // const [active, setActive] = useState(false);
  // const handleLogoClick = (e) => {
  //     e.preventDefault();
  //     setActive(!active);

  // }

  const handleMenuChange = () => {
    setMenutoggle(!menutoggle);
  };

  let navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const auth = localStorage.getItem("userInfo");

  // const openInNewTab = (url) => {
  //     const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  //     if (newWindow) newWindow.opener = null
  // }

  function logOut(e) {
    e.preventDefault();
    setAlert({
      fade: "fade-in",
      msg: "Do you want to logout?",
      type: "logout",
    });
  }
  
  function logoutFunction() {
    authCtx.logout();
    localStorage.clear();
    setAlert({
      fade: "fade-default",
      msg: "",
      type: "",
    });
    navigate("/login");
  }

  useEffect(() => {
    if (logoutdata) {
      logoutFunction();
    }
  }, [logoutdata]);

  function ChangePW(e) {
    e.preventDefault();
    setAlert({
      fade: "fade-in",
      msg: "",
      type: "change",
    });
  }

  const { width } = UsewindowDimension();
  const handleDrawer = () => {
    if (width < 1100) {
      setMenutoggle(!menutoggle);
    } else {
      setMenutoggle(menutoggle);
    }
    setPopupA(false);
    setPopups(false);
    setSelectClass("");
    setSelectedOptions([]);
    setStudentList([]);

    //For Marksinput List
    setSelectSection("");
    setSelectTeacher("");
    setSelectSubject("");
    setSelectTerm("");
    //For Marksinput List ends
  };

  const [open, setOpen] = useState(false);
  // const isTrue = rolePermission.some(
  //   (role) =>
  //     role.UserID === userinfo.UserID &&
  //     role.Permission === "ONLINEPAYMENTREPORT"
  // );

  const [isPayment, setisPayment] = useState(false);
  const [isReportCard, setIsReportCard] = useState();
  // useEffect(() => {
  //   if (userinfo.UserType === "A") {
  //     setisPayment(rolePermission.some(
  //       (role) =>
  //         role.UserID === userinfo.UserID &&
  //         role.Permission === "ONLINEPAYMENTREPORT"
  //     ))
  //     setIsReportCard(rolePermission.some(
  //       (role) =>
  //         role.UserID === userinfo.UserID &&
  //         role.Permission === "REPORTCARD"
  //     ))
  //   }
  // }, [])

  const items = [
    {
      title: "Dashboard",
      icon: "bi bi-speedometer2 mx-3 icon",
      path: "/dashboard",
    },

    userinfo.UserType === "T"
      ? {
          title: "Student",
          icon: "bi bi-person-circle mx-3 icon",
          childrens: [
            {
              title: "Student List",
              path: "/student-list",
            },
          ],
        }
      : {
          title: "Student",
          icon: "bi bi-person-circle mx-3 icon",
          childrens: [
            {
              title: "Student List",
              path: "/student-list",
            },
            !isReportCard && {
              title: "Report Card",
              path: "/report",
            },
            {
              title: "Fee",
              path: "/admin--account",
            },
          ],
        },
    userinfo.UserType === "T"
      ? {
          title: "Teacher",
          icon: "bi bi-person-circle mx-3 icon",
          childrens: [
            {
              title: "Teaching Subject",
              path: "/subject",
            },
          ],
        }
      : {
          title: "Teacher",
          icon: "bi bi-person-circle mx-3 icon",
          childrens: [
            {
              title: "Teacher",
              path: "/teacher-list",
            },
            {
              title: "Designation",
              path: "/designation",
            },
            {
              title: "Teaching Subject",
              path: "/subject",
            },
          ],
        },

    userinfo.UserType === "A"
      ? {
          title: "Admin",
          icon: "bi bi-person-circle mx-3 icon",
          path: "/admin-list",
        }
      : {},
    {
      title: "Non Teaching Staff",
      icon: "bi bi-person-circle mx-3 icon",
      path: "/sch-staff",
    },
    {
      title: "Admission",
      icon: "fa fa-id-card-o mx-3 icon",
      path: "/sch-admission",
    },
    {
      title: "Help Desk",
      icon: "fa fa-id-card-o mx-3 icon",
      path: "/help-desk",
    },
    {
      title: "Academic Year",
      icon: "fa fa-id-card-o mx-3 icon",
      path: "/academic-year",
    },
    // {
    //   title: "Public Admission",
    //   icon: "fa fa-id-card-o mx-3 icon",
    //   path: "/admission",
    // },
    userinfo.UserType === "A" || userinfo.UserType === "T"
      ? {
          title: "Log Book",
          icon: "fa fa-file-text-o mx-3 icon",
          path: "/log-book",
        }
      : {},

    {
      title: "Attendance",
      icon: "fa fa-id-card-o mx-3 icon",
      path: "/attendance",
    },
    {
      title: "Teacher Attendance",
      icon: "bi bi-journal-medical mx-3 icon",
      path: "/teacherAttendance",
    },
    {
      title: "Marks Input",
      icon: "fa fa-file-text-o mx-3 icon",
      path: "/admin--marks",
    },
    userinfo.UserType !== "T"
      ? {
          title: "Class/Section/Term",
          icon: "fa fa-window-maximize mx-3 icon",
          path: "/class-section-term-subject",
        }
      : {},
    {
      title: "Online Class",
      icon: "fa fa-laptop mx-3 icon",
      path: "/online",
    },
    {
      title: "Routine",
      icon: "fa fa-file-text-o mx-3 icon",
      path: "/admin-routine",
    },
    {
      title: "Event",
      icon: "bi bi-calendar-date mx-3 icon",
      path: "/admin-event",
    },

    userinfo.UserType === "A"
      ? {
          title: "QR Payment",
          icon: "bi bi-credit-card mx-3 icon",
          path: "/qr-payment",
        }
      : {},

    userinfo.UserType === "A"
      ? {
          title: "GPS",
          icon: "bi bi-menu-up mx-3 icon",
          childrens: [
            {
              title: "Bus",
              path: "/bus",
            },
            {
              title: "Assign Bus",
              path: "/assign-bus",
            },
          ],
        }
      : {},

    {
      title: "Syllabus",
      icon: "bi bi-menu-up mx-3 icon",
      childrens: [
        {
          title: "Syllabus PDF",
          path: "/syllabus-pdf",
        },
        {
          title: "Chapter",
          path: "/chapter",
        },
        {
          title: "Course",
          path: "/course",
        },
      ],
    },
    userinfo.UserType === "T"
      ? {
          title: "Suggestion",
          icon: "fa fa-comments-o mx-3 icon",
          path: "/teacher-suggestion",
        }
      : {
          title: "Suggestion",
          icon: "fa fa-comments-o mx-3 icon",
          path: "/suggestion",
        },
    // (userinfo.UserType !== 'T' ? (
    //     {
    //         "title": "Academic",
    //         "icon": "fa fa-graduation-cap mx-3 icon",
    //         "path": "/academic"
    //     }) : ({})),
    {
      title: "Gallery",
      icon: "bi bi-images mx-3 icon",
      path: "/gallery-photo",
    },
    {
      title: "Blog",
      icon: "bi bi-chat-square-dots mx-3 icon",
      path: "/blog",
    },
    userinfo.UserType !== "T"
      ? {
          title: "Cafeteria",
          icon: "bi bi-menu-up mx-3 icon",
          childrens: [
            {
              title: "Menu",
              path: "/menu",
            },
            {
              title: "Order",
              path: "/admin-order",
            },
            {
              title: "Payment",
              path: "/order-payment",
            },
            {
              title: "Meal",
              path: "/meal",
            },
          ],
        }
      : {},
    userinfo.UserType !== "T"
      ? {
          title: "Job",
          icon: "bi bi-briefcase mx-3 icon",
          path: "/job",
        }
      : {},
    {
      title: "Admin Leave",
      icon: "bi bi-box-arrow-right mx-3 icon",
      path: "/admin-leave",
    },
    {
      title: "Leave Application",
      icon: "fa fa-newspaper-o mx-3 icon",
      path: "/leave-application",
    },
    {
      title: "Notification",
      icon: "bi bi-bell mx-3 icon",
      path: "/notification",
    },
    userinfo.UserType !== "T"
      ? {
          title: "Slider",
          icon: "fa fa-sliders mx-3 icon",
          path: "/slider",
        }
      : {},
    {
      title: "Assignment",
      icon: "bi bi-book-half mx-3 icon",
      path: "/assignment",
    },
    {
      title: "Quick Assignment",
      icon: "bi bi-book-half mx-3 icon",
      path: "/quick-assignment",
    },
    userinfo.UserType !== "T" && !isPayment
      ? {
          title: "Online Payment",
          icon: "bi bi-credit-card mx-3 icon",
          path: "/payment",
        }
      : {},
    {
      title: "Study Material",
      icon: "bi bi-journal-medical mx-3 icon",
      path: "/study-material",
    },

    userinfo.UserType !== "T"
      ? {
          title: "App Usage",
          icon: "bi bi-person-workspace mx-3 icon",
          path: "/appusage",
        }
      : {},
  ];

  const studentItems = [
    {
      title: "Dashboard",
      icon: "bi bi-speedometer2 mx-3 icon",
      path: "/student-dashboard",
    },
    {
      title: "Routine",
      icon: "fa fa-file-text-o mx-3 icon",
      path: "/student-routine",
    },
    {
      title: "Notification",
      icon: "bi bi-bell mx-3 icon",
      path: "/student-notification",
    },
    {
      title: "Assignment",
      icon: "bi bi-book-half mx-3 icon",
      path: "/student-assignment",
    },
    {
      title: "Course Plan",
      icon: "bi bi-book-half mx-3 icon",
      path: "/student-course",
    },
    {
      title: "Attendance",
      icon: "fa fa-id-card-o  mx-3 icon",
      childrens: [
        {
          title: "Report",
          path: "/student-attendance-report",
        },
        {
          title: "Summary",
          path: "/student-attendance-summary",
        },
      ],
    },
    {
      title: "Report Card",
      icon: "bi bi-file-earmark-spreadsheet mx-3 icon",
      path: "/student-report",
    },
    {
      title: "Log Book",
      icon: "fa fa-file-text-o mx-3 icon",
      path: "/student-log-book",
    },
    {
      title: "Account",
      icon: "bi bi-cash-coin mx-3 icon",
      path: "/student-account",
    },
    {
      title: "Payment",
      icon: "bi bi-cash-coin mx-3 icon",
      path: "/student-payment",
    },
    {
      title: "Online Class",
      icon: "bi bi-laptop mx-3 icon",
      path: "/student-onlineclass",
    },
    {
      title: "Leave Notes",
      icon: "bi bi-file-ruled mx-3 icon",
      path: "/student-leave-application",
    },
    {
      title: "Gallery",
      icon: "bi bi-images mx-3 icon",
      path: "/student-gallery",
    },
    {
      title: "Cafeteria",
      icon: "bi bi-menu-up mx-3 icon",
      childrens: [
        {
          title: "Menu",
          path: "/student-cafeteria",
        },
        {
          title: "Order",
          path: "/student-order",
        },
      ],
    },
    {
      title: "Study Material",
      icon: "bi bi-journal-medical mx-3 icon",
      path: "/student-study-material",
    },
    {
      title: "Suggestion",
      icon: "bi bi-journals mx-3 icon",
      path: "/student-suggestion",
    },
    {
      title: "Blog",
      icon: "bi bi-chat-square-dots mx-3 icon",
      path: "/student-blog",
    },
    {
      title: "Profile",
      icon: "bi bi-person-circle mx-3 icon",
      path: "/student-profile",
    },
  ];

  const canteenItems = [
    {
      title: "Dashboard",
      icon: "bi bi-speedometer2 mx-3 icon",
      path: "/canteen-dashboard",
    },
    {
      title: "Menu",
      icon: "fa fa-file-text-o mx-3 icon",
      path: "/canteen-menu",
    },
  ];

  /* This is for Admin and teacher search */

  const [searchQuery, setSearchQuery] = useState("");
  const filteredItemsForAdmin = items.filter(
    (item) =>
      item.title && item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  /* This is for Admin and teacher search ends */

  /* This is for Student search */

  const [searchQuerys, setSearchQuerys] = useState("");
  const filteredItemsForStudent = studentItems.filter((item) =>
    item.title.toLowerCase().includes(searchQuerys.toLowerCase())
  );

  /* This is for Admin and teacher search ends */

  const [backgroundColor, setBackgroundColor] = useState("#f5f8fa");

  const handleInputClick = () => {
    setBackgroundColor("white");
  };

  return (
    <>
      {auth ? (
        <>
          <div className="menu">
            <div className="img ">
              <img src={logo} alt="" className="imgMain" />
              <span className="logoHeading">Easy School</span>
              <span className="close-button" onClick={handleMenuChange}>
                <i className="fas fa-times"></i>
              </span>
            </div>
            {userinfo.UserType === "S" ? (
              <nav className="MainNavbar">
                <div className="mainNavUserInfo">
                  <div className="sidebarLogo">
                    <div className="sidebarImg">
                      <img
                        className="SidebarImage"
                        src={userinfo.UserImage}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="userInfotext">
                    {" "}
                    <p className="mb-0">{FullName}</p>
                    <p className="mb-0" style={{ textAlign: "left" }}>
                      {UserName}
                    </p>
                  </div>
                </div>
                <div className="sidebar-title">
                  <span>
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm sidebar-search"
                      value={searchQuerys}
                      onChange={(e) => setSearchQuerys(e.target.value)}
                    />
                  </span>
                  <i
                    className="fa fa-search"
                    style={{ top: "10px", position: "relative" }}
                  ></i>
                </div>

                {filteredItemsForStudent.map((item, index) => (
                  <SidebarItem key={index} item={item} />
                ))}

                <ul>
                  <div className={open ? "sidebar-item open" : "sidebar-item"}>
                    <div
                      className="sidebar-title"
                      onClick={() => setOpen(!open)}
                    >
                      <span>
                        <i className="fa fa-graduation-cap mx-3 icon"></i>School
                      </span>
                      <i
                        className={
                          open ? "fas fa-angle-up" : "fas fa-angle-down"
                        }
                        style={{ position: "relative", top: "5px" }}
                      ></i>
                    </div>
                    <div className="sidebar-content">
                      <NavLink
                        activeclassname={classes.active}
                        to="/student-about"
                        className="sidebar-item plain arrow"
                      >
                        <div className="sidebar-title">
                          <span onClick={handleDrawer}>About School</span>
                        </div>
                      </NavLink>
                      <NavLink
                        activeclassname={classes.active}
                        className="sidebar-item plain arrow"
                      >
                        <div className="sidebar-title">
                          <span onClick={ChangePW}>Change Password</span>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </ul>
              </nav>
            ) : userinfo.UserType === "C" ? (
              <>
                <nav className="MainNavbar">
                  <div className="mainNavUserInfo">
                    <div className="sidebarLogo">
                      <div className="sidebarImg">
                        <img
                          className="SidebarImage"
                          src={userinfo.UserImage}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="userInfotext">
                      {" "}
                      <p className="mb-0">{FullName}</p>
                      <p className="mb-0" style={{ textAlign: "left" }}>
                        {UserName}
                      </p>
                    </div>
                  </div>
                  {canteenItems.map((item, index) => (
                    <SidebarItem key={index} item={item} />
                  ))}
                </nav>
              </>
            ) : (
              <nav className="MainNavbar">
                <div className="mainNavUserInfo">
                  <div className="sidebarLogo">
                    <div className="sidebarImg">
                      <img
                        className="SidebarImage"
                        src={userinfo.UserImage}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="userInfotext">
                    {" "}
                    <p className="mb-0">{FullName}</p>
                    <p className="mb-0" style={{ textAlign: "left" }}>
                      {UserName}
                    </p>
                  </div>
                </div>

                <div className="sidebar-title-search">
                  <span>
                    <input
                      type="text"
                      // placeholder={backgroundColor === "white" ? "Search" : ""}
                      placeholder="Search"
                      className="form-control form-control-sm sidebar-search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      // style={{ backgroundColor }}
                      // onClick={handleInputClick}
                    />
                  </span>

                  <i
                    className="fa fa-search"
                    // onClick={handleInputClick}
                    style={{ top: "10px", position: "relative" }}
                  ></i>
                </div>

                {filteredItemsForAdmin.length > 0 ? (
                  filteredItemsForAdmin.map((item, index) => (
                    <SidebarItem
                      key={index}
                      item={item}
                      handleDrawer={handleDrawer}
                    />
                  ))
                ) : (
                  <></>
                )}

                <ul className="m-0">
                  <div className={open ? "sidebar-item open" : "sidebar-item"}>
                    <div
                      className="sidebar-title"
                      onClick={() => setOpen(!open)}
                    >
                      <span>
                        <i className="fa fa-graduation-cap mx-3 icon"></i>School
                      </span>
                      <i
                        className={
                          open ? "fas fa-angle-up" : "fas fa-angle-down"
                        }
                        style={{ top: "6px", position: "relative" }}
                      ></i>
                    </div>
                    <div className="sidebar-content" onClick={handleDrawer}>
                      {userinfo.UserType === "T" ? (
                        <NavLink
                          activeclassname={classes.active}
                          to="/teacher-school"
                          className="sidebar-item plain arrow"
                        >
                          <div className="sidebar-title">
                            <span onClick={handleDrawer}>About</span>
                          </div>
                        </NavLink>
                      ) : (
                        <NavLink
                          activeclassname={classes.active}
                          to="/about-school"
                          className="sidebar-item plain arrow"
                        >
                          <div className="sidebar-title">
                            <span onClick={handleDrawer}>About</span>
                          </div>
                        </NavLink>
                      )}

                      <NavLink
                        activeclassname={classes.active}
                        className="sidebar-item plain arrow"
                      >
                        <div className="sidebar-title">
                          <span onClick={ChangePW}>Change Password</span>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </ul>
              </nav>
            )}
          </div>
        </>
      ) : (
        <>
          <li className="nav-item">
            <NavLink
              activeClassName={classes.active}
              className="nav-link"
              to="/login"
            >
              Login
            </NavLink>
          </li>
        </>
      )}
    </>
  );
}
