import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import MealContext from "./MealContext";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";

function MealState(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const { appURL } = useContext(SidebarContext);

  const [mealPopup, setMealPopup] = useState(false);

  const togglePopup = () => {
    setMealPopup(!mealPopup);
  };

  const [mealEditPopup, setMealEditPopup] = useState(false);

  const toggleEditPopup = () => {
    setMealEditPopup(!mealEditPopup);
  };

  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);
  const [closeChecked, setCloseChecked] = useState(false);

  const mealValue = {
    itemName: "",
    category: "",
    type: "",
    price: "",
    unit: "",
    description: "",
  };
  const [mealFormValue, setMealFormValue] = useState(mealValue);
  const [mealFormError, setMealFormError] = useState({});

  const [chooseMeal, setChooseMeal] = useState("-1");
  const [chooseType, setChooseType] = useState("-1");
  const [chooseAvailable, setChooseAvailable] = useState("-1");

  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState();
  const [image, setImage] = useState("");

  const [isAvailable, setIsAvailable] = useState(false);

  /* This is for Meal  Category popup */
  const categoryValue = {
    categoryName: "",
  };

  const [categoryPopup, setCategoryPopup] = useState(false);

  const toggleCategoryPopup = () => {
    setCategoryPopup(!categoryPopup);
  };

  const [categoryFormValue, setCategoryFormValue] = useState(categoryValue);
  const [categoryFormError, setCategoryFormError] = useState({});
  const [categoryIsSubmit, setCategoryIsSubmit] = useState(false);
  const [categoryTopicSearch, setCategoryTopicSearch] = useState(false);

  const addCategory = (e) => {
    e.preventDefault();
    setCategoryPopup(true);
    setCategoryFormValue(categoryValue);
  };

  /* This is for Meal Category popup ends*/

  const [isSubmit, setIsSubmit] = useState(false);
  const [editIsSubmit, setEditIsSubmit] = useState(false);

  /***********************************************Meal category api starts ************************ */

  // For Meal category create
  const categoryData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      Name: categoryFormValue.categoryName,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/meal-category`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        setCategoryFormValue(categoryValue);
        getMealList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };







  // For Meal Category edit
  const [disEdit, setDisEdit] = useState(false);
  const [disId, setDisId] = useState("");

  function selectUser(datas) {
    setCategoryFormValue({
      categoryName: datas.Name,
    });
    setDisId(datas.CategoryID);
    setDisEdit(true);
  }

  const editData = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "U",
      Name: categoryFormValue.categoryName,
      CategoryID: disId,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/meal-category`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getMealList();
        setCategoryFormValue(categoryValue);
        setDisEdit(false);
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  // For Meal Category edit ends
  // For Meal Category deactivate/ activate starts

  const deactivateCategory = (ID, IsActives) => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      CategoryID: ID,
      IsActive: IsActives,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/meal-category`,
      Type: "POST",
    };
    if (IsActives === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getMealList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast(result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // For Meal Category deactivate/ activate ends

  /***********************************************Meal Category api ends ********************* */

  //API for Meal create

  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "I",
      MealType: mealFormValue.type,
      CategoryID: mealFormValue.category,
      Image: image !== null ? image.split(",")[1] : "",
      Name: mealFormValue.itemName,
      Description: mealFormValue.description,
      Currency: "RS",
      Price: mealFormValue.price,
      PriceUnit: mealFormValue.unit,
      IsAvail: isAvailable === true ? "Y" : "N",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/meal`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getMealList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //API to hit Meal list
  const [mealList, setMealList] = useState([]);

  useEffect(() => {
    getMealList();
  }, [chooseAvailable, chooseType, chooseMeal]);

  const getMealList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "S",
      IsActive: "-1",
      IsAvail: chooseAvailable,
      MealType: chooseType,
      CategoryID: chooseMeal,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/canteen/meal`,
      Type: "POST",
    };
    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.Values ? result.Values : "";
        setMealList(postResult);
        setOriginalList(postResult);
        setLoading(false);
      } else {
        setOriginalList([]);
        setMealList([]);
      }
    });
  };

  const [titleId, setTitleID] = useState();
  const editPop = (datas) => {
    setMealEditPopup(true);
    setTitleID(datas.MealID);
    mealInfo();
    setEditIsSubmit(false);
  };

  // For Meal info
  useEffect(() => {
    mealInfo();
  }, [titleId]);

  const mealInfo = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "SI",
      MealID: titleId,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/meal`,
      Type: "POST",
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const data = result.Values[0] ? result.Values[0] : "";
        setMealFormValue({
          itemName: data.Name,
          category: data.CategoryID,
          type: data.MealTypeID,
          price: data.Price,
          unit: data.PriceUnit,
          description: data.Description,
        });
        setImage(data.Image);
        setIsAvailable(data.IsAvail === "Y" ? true : false);
      }
    });
  };

  const editMealdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "U",
      MealID: titleId,
      MealType: mealFormValue.type,
      CategoryID: mealFormValue.category,
      Image: image !== null ? image.split(",")[1] : "",
      Name: mealFormValue.itemName,
      Description: mealFormValue.description,
      Currency: "RS",
      Price: mealFormValue.price,
      PriceUnit: mealFormValue.unit,
      IsAvail: isAvailable === true ? "Y" : "N",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/meal`,
      Type: "POST",
    };
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getMealList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //API to hit Meal status

  const deactivateDepart = (ID, IsActives) => {
    const dataForm = {
      // AuthCode: User.AuthCode,
      // ComID: User.CompanyId,
      // UserID: User.UID.toString(),
      // Flag: "AI",
      // IsActive: IsActives,
      // MealID: ID,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/restro/admin/meal`,
      Type: "POST",
    };
    if (IsActives === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getMealList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
          theme: "light",
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
          theme: "light",
        });
      }
    });
  };

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [activatePopup, setActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [Acdata, setACData] = useState();

  const handleActivate = async (data) => {
    setACData(data.MealID);

    setActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      MealID: Acdata,
      IsAvail: chooseAvailable,
      MealType: chooseType,
      CategoryID: chooseMeal,
      IsActive: "A",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/meal`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getMealList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleActivateFalse = () => {
    setActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and activate  ends

  //Activate popup starts from here
  //Everything to pop up and approve the  list
  const [inActivatePopup, setInActivatePopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });
  // To approve the credit List

  const [inactivedata, setInactiveData] = useState();

  const handleInActivate = async (data) => {
    setInactiveData(data.MealID);

    setInActivatePopup({
      show: true,
      data,
    });
  };

  // This will perform the approve and hide the Confirmation Box

  const handleInActivateTrue = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "AI",
      MealID: inactivedata,
      IsAvail: chooseAvailable,
      MealType: chooseType,
      CategoryID: chooseMeal,
      IsActive: "I",
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${appURL}api/admin/meal`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          getMealList();
          toast(resp.Message, {
            style: {
              color: "green",
              fontSize: "13px",
            },
          });
        } else {
          toast(resp.Message, {
            style: {
              color: "red",
              fontSize: "13px",
            },
          });
        }
      });
    });
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleInActivateFalse = () => {
    setInActivatePopup({
      show: false,
      data: null,
    });
  };

  //Everything to pop up and approve the credit list ends

  return (
    <MealContext.Provider
      value={{
        setMealPopup,
        loading,
        reload,
        setReload,
        mealFormValue,
        mealValue,
        setMealFormValue,
        mealFormError,
        setMealFormError,
        mealPopup,
        mealEditPopup,
        editPop,
        originalList,
        mealList,
        deactivateDepart,
        fetchdata,
        isSubmit,
        setIsSubmit,
        editIsSubmit,
        setEditIsSubmit,
        editMealdata,
        addCategory,
        setMealEditPopup,
        isAvailable,
        setIsAvailable,
        setMealList,

        categoryPopup,
        setCategoryPopup,
        categoryFormValue,
        setCategoryFormValue,
        categoryFormError,
        setCategoryFormError,
        categoryIsSubmit,
        setCategoryIsSubmit,
        categoryTopicSearch,
        setCategoryTopicSearch,
        categoryData,
       
        disEdit,
        setDisEdit,
        selectUser,
        editData,
        categoryValue,
     

        isUploaded,
        setIsUploaded,
        typeFile,
        image,
        setTypeFile,
        setImage,
        chooseMeal,
        setChooseMeal,
        chooseType,
        setChooseType,
        chooseAvailable,
        setChooseAvailable,
        closeChecked,
        setCloseChecked,
        togglePopup,
        toggleCategoryPopup,
        toggleEditPopup,

        activatePopup,
        setActivatePopup,
        handleActivate,
        handleActivateTrue,
        handleActivateFalse,
        inActivatePopup,
        setInActivatePopup,
        handleInActivate,
        handleInActivateTrue,
        handleInActivateFalse,
        deactivateCategory,
      }}
    >
      {props.children}
    </MealContext.Provider>
  );
}
export default MealState;
