import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import AcademicYearContext from "./AcademicYearContext";
import { GetEnglishDate, GetNepaliDate } from "../../hooks/dateConvertor";
import { FetchNewdata } from "../../hooks/getData";

function AcademicYearState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [closeChecked, setCloseChecked] = useState(false);

  const AcademicYearValue = {
    AcademicYear: "",
    YearFrom: "",
    YearTo: "",
  };

  const [formValue, setFormValue] = useState(AcademicYearValue);
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [active, setActive] = useState(false);

  const [popup, setPopup] = useState(false);
  const togglePopup = () => {
    setPopup(!popup);
  };

  //API to create admission
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID.toString(),
      Flag: "i",
      AcademicYear: formValue.AcademicYear,
      YearFrom: formValue.YearFrom,
      YearTo: formValue.YearTo,
      IsActive: active ? "Y" : "N",
      FetchURL: `${appURL}api/admin/academic-year`,
      Type: "POST",
    };

    FetchNewdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getAcademicYearList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  // API for admission List
  const [AcademicYearList, setAcademicYearList] = useState([]);

  useEffect(() => {
    if (userinfo && userinfo.SchID) { // Check if user info is available
        getAcademicYearList();
    }
}, []); // Add userinfo to dependencies to re-run if it changes

  const getAcademicYearList = () => {
    const params = {
      SchID: userinfo.SchID,
      Flag: "S",
      AuthCode: "r1d3r",
      Type: "POST",
      FetchURL: `${appURL}api/admin/academic-year`,
    };

    FetchNewdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.AcademicYearLst ? result.AcademicYearLst : "";
        setLoading(false);
        setAcademicYearList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setAcademicYearList([]);
        setOriginalList([]);
      }
    });
  };

  // Ends Here

  return (
    <AcademicYearContext.Provider
      value={{
        loading,
        originalList,
        popup,
        setPopup,
        togglePopup,
        AcademicYearList,
        setAcademicYearList,
        formValue,
        setFormValue,
        formError,
        setFormError,
        isSubmit,
        AcademicYearValue,
        setIsSubmit,
        closeChecked,
        setCloseChecked,
        fetchdata,
        active,
        getAcademicYearList,
        setActive,
      }}
    >
      {props.children}
    </AcademicYearContext.Provider>
  );
}

export default AcademicYearState;
