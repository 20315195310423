import React, { useContext, useEffect, useState } from "react";
import { Fetchdata } from "../../hooks/getData";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { toast } from "react-toastify";
import MarksInputContext from "./MarksInputContext";

function MarksInputState(props) {
  const { appURL } = useContext(SidebarContext);

  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState(null);

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));

  const [markList, setMarkList] = useState([]);

  const [isSubmit, setIsSubmit] = useState(false);

  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [termList, setTermList] = useState([]);

  const [selectClass, setSelectClass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [selectTeacher, setSelectTeacher] = useState("");
  const [selectSubject, setSelectSubject] = useState("");
  const [selectTerm, setSelectTerm] = useState("");

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      DFlag: "C",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setClassList(postResult);
      } else {
        setClassList([]);
      }
    });
  };

  useEffect(() => {
    getSectionList();
  }, [selectClass]);

  const getSectionList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      ClassID: selectClass,
      DFlag: "S",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSectionList(postResult);
      } else {
        setSectionList([]);
      }
    });
  };

  //API to hit Teacher list

  useEffect(() => {
    teachList();
  }, []);

  useEffect(() => {
    teachList();
  }, [selectClass, selectSection]);

  const teachList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      DFlag: "TL",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectSection,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setTeacherList(postResult);
      } else {
        setTeacherList([]);
      }
    });
  };

  // API to hit Subject List
  useEffect(() => {
    getSubjectList();
  }, [selectClass, selectTeacher, selectSection]);

  const getSubjectList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : selectTeacher,
      ClassID: selectClass,
      SectionID: selectSection,
      DFlag: "SB",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";
        setSubjectList(postResult);
      } else {
        setSubjectList([]);
      }
    });
  };

  //API to hit Terminal list

  useEffect(() => {
    getTermList();
  }, []);

  const getTermList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: "-1",
      DFlag: "T",
      Flag: "S",
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.referencelst ? result.referencelst : "";

        setLoading(false);
        setTermList(postResult);
      } else {
        setTermList([]);
      }
    });
  };

  // API for marksinput List
  const [marksInputList, setMarksInputList] = useState([]);

  useEffect(() => {
    getMarksInputList();
  }, [selectTerm, selectClass, selectSection, selectSubject]);

  const getMarksInputList = () => {
    const params = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserType === "T" ? userinfo.UserID.toString() : "-1",
      Flag: "S",
      ClassID: selectClass,
      SectionID: selectSection,
      SubjectID: selectSubject,
      TermID: selectTerm,
      Type: "POST",
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/marks-input`,
    };

    Fetchdata(params).then(function (result) {
      if (result.StatusCode === 200) {
        const postResult = result.MarksInputLst ? result.MarksInputLst : "";

        setLoading(false);
        setMarksInputList(postResult);
        setOriginalList(postResult);
      } else {
        setLoading(false);
        setMarksInputList([]);
        setOriginalList([]);
      }
    });
  };

  // const initialState = Object.fromEntries(
  //     marksInputList.map(({ MemID }) => [MemID, { MemID: MemID, PrObtain: "0.0", ThObtain: "0.0" }])
  // );

  // const [marks, setMarks] = useState(initialState);

  // function getAttendanceArray() {
  //     return Object.values(marks);
  // }

  // const marksInput = getAttendanceArray();

  //

  //API to create Marks Input
  const fetchdata = () => {
    const dataForm = {
      SchID: userinfo.SchID,
      UserID: userinfo.UserID,
      Flag: "i",
      ClassID: selectClass,
      SectionID: selectSection,
      SubjectID: selectSubject,
      TermID: selectTerm,
      MarksType: "M",
      IsOnline: "Y",
      Param: marksInputList,
      // Param: markList,
      Academicyear: userinfo.AcademicYear,
      FetchURL: `${appURL}api/admin/marks-input`,
      Type: "POST",
    };

    Fetchdata(dataForm).then(function (result) {
      if (result.StatusCode === 200) {
        getMarksInputList();
        toast(result.Message, {
          style: {
            color: "green",
            fontSize: "13px",
          },
        });
      } else {
        toast("Error: " + result.Message, {
          style: {
            color: "red",
            fontSize: "13px",
          },
        });
      }
    });
  };

  return (
    <MarksInputContext.Provider
      value={{
        loading,
        originalList,
        setIsSubmit,
        isSubmit,
        classList,
        sectionList,
        teacherList,
        selectClass,
        setSelectClass,
        selectSection,
        setSelectSection,
        selectTeacher,
        setSelectTeacher,
        fetchdata,
        marksInputList,
        setMarksInputList,
        subjectList,
        setSubjectList,
        termList,
        setTermList,
        selectSubject,
        setSelectSubject,
        selectTerm,
        setSelectTerm,
        markList,
        setMarkList,

        // marks, setMarks,
        setLoading,
      }}
    >
      {props.children}
    </MarksInputContext.Provider>
  );
}

export default MarksInputState;
